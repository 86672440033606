<template>
  <div>
    <van-nav-bar
      class="backBox"
      title="商户端"
      right-text=""
      left-arrow
      @click-left="onClickLeft"
    />

    <div class="page" :style="{ marginTop: navHeight }">
      <!--pages/vip/vip.wxml-->
      <div class="pageBox">
        <!--   next-margin="50rpx"
          previous-margin="50rpx" -->

        <van-swipe class="my-swipe" :autoplay="3000" @change="switchTab">
          <!-- <block v-for="{{4}}" v-key="index"> -->

          <van-swipe-item
            v-for="item in couponRuleList"
            :key="item.id"
            @click="openCouponDetail"
          >
            <div
              :class="[
                'li',
                item.type == 3 ? 'li_0' : '',
                item.type == 2 ? 'li_1' : '',
                item.type == 1 ? 'li_2' : '',
                item.type == 4 ? 'li_3' : '',
              ]"
            >
              <!-- 折扣减免券 li_0 type=3-->
              <!-- 时长减免券 li_1 type=2-->
              <!-- 金额减免券 li_2 type=1-->
              <!-- 全免券 li_3 type=4-->
              <div class="liBox">
                <!-- 测试图片 -->
                <div class="li_top">
                  <div class="tit">
                    <span class="name">
                      {{
                        item.type === 1
                          ? '金额减免券'
                          : item.type === 2
                          ? '时长减免券'
                          : item.type === 3
                          ? '折扣减免券'
                          : item.type === 4
                          ? '全免券'
                          : ''
                      }}
                    </span>

                    <span class="num" v-if="item.type == 3 || item.type == 4"
                      >{{ item.total - item.granted + item.reverted }}张</span
                    >
                    <span class="num num_money" v-if="item.type == 1"
                      ><span class="money_flag">￥</span>
                      {{ item.leftNum / 100 }}</span
                    >
                    <span class="num num_date" v-if="item.type == 2">
                      <span>{{ changeHourLocal(item.leftNum) }}</span>
                      <span class="hourText">小时</span>
                      <span> {{ changeMinuteLocal(item.leftNum) }}</span>
                      <span class="hourText">分钟</span>
                    </span>
                  </div>
                  <!-- <div class="date" v-if="{{filter.getTime(item.e_s_time)=='1970-01-01'&&filter.getTime(item.e_s_time)=='2999-12-31' }}">有效期：长期有效</div> -->
                  <div
                    class="date"
                    v-if="
                      judgeEndDateLocal(item.e_s_time) ||
                      judgeStartDateLocal(item.e_e_time)
                    "
                  >
                    有效期：长期有效
                  </div>
                  <div class="date" v-else>
                    有效期：{{ formatDate(item.e_s_time) }} 至
                    {{ formatDate(item.e_e_time) }}
                  </div>
                </div>
                <div class="li_bottom">
                  <div class="btn btnLeft" @click="openErweimaCoupon">
                    <div class="btnImg">
                      <img
                        src="../../assets/img/couponCeremaimg@2x.png"
                        mode=""
                      />
                    </div>
                    <span class="btnText">车主扫码领券</span>
                  </div>
                  <div class="btn btnRight" @click="openNumCoupon">
                    <div class="btnImg">
                      <img src="../../assets/img/couponNuming@2x.png" mode="" />
                    </div>
                    <span class="btnText">按照车牌发券</span>
                  </div>
                </div>
              </div>
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>

      <div class="box_1">
        <div class="accessTit">快捷入口</div>
        <div class="list_11">
          <div class="image-text_23-0" @click="payCoupon">
            <img src="../../assets/img/goumaipayimg@2x.png" class="label_7-0" />

            <span class="text-group_9-0">购买优惠券</span>
          </div>
          <div class="image-text_23-0" @click="giveCoupon">
            <img src="../../assets/img/fafangpayimg@2x.png" class="label_7-0" />
            <span class="text-group_9-0">发放优惠券</span>
          </div>
          <div class="image-text_23-0" @click="openGiveRecord">
            <img src="../../assets/img/fafangrecord@2x.png" class="label_7-0" />
            <span class="text-group_9-0">发放记录</span>
          </div>

          <div class="image-text_23-0" @click="openPayRecord">
            <img src="../../assets/img/goumairecord@2x.png" class="label_7-0" />
            <span class="text-group_9-0">购买记录</span>
          </div>
        </div>
      </div>

      <div class="box_28">
        <!-- 广告位置 -->
        <div class="addBox">
          <!-- <image src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/58d8b65c5d3444debaa5bc4bef3bc4c0_mergeImage.png" mode="" /> -->
          <van-image width="100%" height="100" :src="adUrl" />
        </div>
      </div>
    </div>
    <van-toast id="van-toast" />
  </div>
</template>
<script>
import * as common from '../../api/common'
import * as constant from '../../api/constant'
export default {
  name: 'BusinessHome',
  data () {
    return {
      adUrl: 'https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/58d8b65c5d3444debaa5bc4bef3bc4c0_mergeImage.png',
      leftArr: [],
      tokenInfoObj: {},
      token: '',
      sid: '',
      customer_id: '',
      tokenObj: {},
      user_info: {},
      couponRuleList: [
        {
          id: 0,
          type: 1,
          total: 100,
          leftNum: 33,
          granted: 4,
          reverted: 8
        },
        {
          id: 1,
          type: 2,
          total: 100,
          leftNum: 33,
          granted: 4,
          reverted: 8
        },
        {
          id: 2,
          type: 3,
          total: 100,
          leftNum: 33,
          granted: 4,
          reverted: 8
        },
        {
          id: 3,
          type: 4,
          total: 100,
          leftNum: 33,
          granted: 4,
          reverted: 8
        }
      ],
      currentTab: 0, // 预设当前项的值
      background: [{
        id: 0,
        name: '1'
      },
      {
        id: 1,
        name: '2'
      },
      {
        id: 2,
        name: '3'
      }
      ],
      indicatorDots: true,
      circular: true,
      vertical: false,
      autoplay: true,
      interval: 5000,
      duration: 500,
      array: ['浙A11111', '浙B12345', '浙C12345', '浙D12345', '浙E12345'],
      index: '',
      closeOnClick: false,
      showHelp: false,
      actions: [{
        name: '13578923456'
      },
      {
        name: '呼叫'
      }
      ],
      navHeight: '', // 导航栏总体高度
      navTop: '', // 胶囊距离顶部距离
      navObj: '', // 胶囊高度
      navObjWid: '', // 胶囊宽度(包括右边距离)
      motto: 'Hello World',
      userInfo: {},
      hasUserInfo: false,
      canIUseGetUserProfile: false

    }
  },
  mounted () {
    const roleType = 2
    this.$store.commit(constant.keyAccountRoleVuex, roleType)
  },
  computed: {

  },
  methods: {
    openErweimaCoupon () {

    },
    openNumCoupon () {

    },
    changeHourLocal () {

    },
    changeMinuteLocal () {

    },
    openCouponDetail () {

    },
    judgeEndDateLocal () {

    },
    judgeStartDateLocal () {

    },
    switchTab () {

    },
    payCoupon () {
      this.$router.push('/payCoupon')
    },
    giveCoupon () {
      this.$router.push('/giveCoupon')
    },
    openGiveRecord () {
      this.$router.push('/giveRecord')
    },
    openPayRecord () {
      this.$router.push('/payRecord')
    },
    formattedDate (dateString) {
      return dateString.replace(/-/g, '/') //
    },
    formatDate (date) {
      return common.fmtDate(date, 'yyyy/MM/dd hh:mm:ss')
    },
    onClickLeft () {
      this.$router.go(-1)
    }

  }
}
</script>
<style lang="scss" scoped>
.pageBox {
  width: 100%;
  /* height: 100%; */
  overflow: hidden;
  margin-top: 0.08rem;
  /* background-color: #1a1728; */
  .my-swipe {
    width: 100%;
    height: 1.34rem;
    top: 0rem;
    left: 0;
    z-index: 999;
    /deep/ .van-swipe-item {
      //  background-color: yellowgreen;

      display: flex;
      justify-content: center;
      // margin-left: -1%;
      // margin-right: -1%;
    }
  }

  .li {
    width: 100%;
    // width: 3.5rem;

    // width: 90%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -1%;
    /* background-color: blueviolet; */
  }

  .li .li_top {
    margin-top: 0.16rem;
    padding: 0px 0.12rem;
  }

  .liBox {
    width: 99%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* padding: 20rpx; */
    margin: 0 0.1rem;
    border-radius: 0.04rem;
  }

  .li_0 .liBox {
    background: linear-gradient(to right, #fff4c1, #fff8d9);
  }

  .li_1 .liBox {
    background: linear-gradient(to right, #abe3ff, #bee2f4);
  }

  .li_0 .liBox .btn {
    background-color: #f1c602;
  }

  .li_1 .liBox .btn {
    background: linear-gradient(to right, #0ea3ed, #74c6ef);
  }

  .li_2 .liBox .btn {
    background: linear-gradient(to right, #2778ec, #4f94f7);
  }

  .li_3 .liBox .btn {
    background: linear-gradient(to right, #f8b98c, #e98740);
  }

  .name {
    color: #000;
    font-size: 0.16rem;
  }

  .date {
    color: #666;
    font-size: 0.11rem;
  }

  .btn {
    color: #fff;
    font-size: 0.12rem;
    border-radius: 0.15rem;
    // border-radius: 0.04rem;
    margin-top: 0.12rem;
    margin-bottom: 0.25rem;
    display: flex;
    padding: 0.08rem 0.16rem;
    /* flex-direction: column; */
    /* padding: 0rpx 50rpx; */
  }

  .btnText {
    margin-left: 0.05rem;
  }

  .li_2 .liBox {
    background: linear-gradient(to right, #caddff, #eef6ff);
  }

  .li_3 .liBox {
    background: linear-gradient(to right, #ffdbca, #fff7ee);
  }

  .liBox .tit {
    display: flex;
    justify-content: space-between;
  }

  .liBox .li_bottom {
    display: flex;
    justify-content: space-between;
    margin: 0 0.14rem;
  }

  .li_0 .tit,
  .li_3 .tit {
    justify-content: flex-start;
  }

  .li_0 .num,
  .li_3 .num {
    /* width: 70rpx; */
    height: 0.2rem;
    line-height: 0.2rem;
    color: #fff;
    font-size: 0.12rem;
    text-align: center;
    margin-left: 0.05rem;
    padding: 0 0.04rem;
  }

  .li_0 .num {
    background-color: #f1c602;
  }

  .li_3 .num {
    background-color: #e98740;
  }

  .li_1 .num,
  .li_2 .num {
    font-size: 0.19rem;
  }

  .li_1 .num {
    color: #0ea3ed;
  }

  .li_2 .num {
    color: #2778ec;
  }

  .moneyType {
    font-size: 24rpx;
    color: #2778ec;
  }

  .btnImg {
    width: 0.15rem;
    height: 0.1rem;
  }

  .btnImg img {
    width: 100%;
    height: 100%;
  }

  .money_flag,
  .hourText {
    font-size: 0.12rem;
  }

  .num_maoney {
    font-size: 0.19rem;
  }

  .num_date {
    font-size: 0.19rem;
  }
}

.box_1 {
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  padding: 0.14rem 0 0.15rem 0;
  .accessTit {
    margin-left: 0.16rem;
    font-size: 0.2rem;
    text-align: left;
  }
  .list_11 {
    width: 3.43rem;
    height: 0.55rem;
    align-self: center;
    margin-top: 0.2rem;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    color: #000;
    font-size: 0.14rem;
    .image-text_23-0 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      /* margin: 8rpx 52rpx 0 0; */
      .label_7-0 {
        width: 0.33rem;
        height: 0.24rem;
        margin: 0 0.09rem 0 0.06rem;
      }
      .text-group_9-0 {
        overflow-wrap: break-word;
        color: #000;
        font-size: 0.12rem;
        font-family: PingFangSC-Regular;
        font-weight: normal;
        text-align: left;
        white-space: nowrap;
        line-height: 0.17rem;
        margin-top: 0.1rem;
      }
    }
  }
}
.box_28 {
  display: flex;
  margin-top: 0.1rem;
  justify-content: center;
  position: fixed;
  bottom: 0;
  padding: 0.16rem;
  .addBox {
    width: 3.44rem;
    height: 0.98rem;
    image {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
